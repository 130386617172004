


  /* Define the DM-sans font */
@font-face {
  font-family: 'DM-sans';
  src:  url('../fonts/DM_Sans/DMSans-VariableFont_opsz\,wght.ttf') format('truetype'), 
}
  /* Define the IntegralCF-bold font */
@font-face {
  font-family: 'IntegralCF-bold';
  src: url('../fonts/integral/IntegralCF-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
 /* Define the Poppins font */
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/poppins/Poppins-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
  /* Define the Satoshi bold font */
 @font-face {
  font-family: 'Satoshi-Bold';
  src: url('../fonts/Satoshi/Satoshi-Bold.otf') format('opentype') ;
  font-weight: bold;
  font-style: normal;
} 
  /* Define the Satoshi bold font */
  @font-face {
    font-family: 'Satoshi-Medium';
    src: url('../fonts/Satoshi/Satoshi-Bold.otf') format('opentype') ;
    font-weight: 500;
    font-style: normal;
  } 
  /* Define the Satoshi regular font */
@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi/Satoshi-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-size: '16px';
}
/* Define the Plus_Jakarta_Sans Bold font */
@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/Plus_Jakarta_Sans/PlusJakartaSans-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-size: '16px';
}

  /* Define the Plus Jakarta Sans regular font */
  @font-face {
    font-family: 'Plus Jakarta Sans Regular';
    src: url('../fonts/Plus_Jakarta_Sans/PlusJakartaSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-size: '16px';
  }
   
  @font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Medium.ttf') format('truetype'); 
  }
  @font-face {
    font-family: 'Sentient';
    src: url('../fonts/sentient/Sentient-Medium.woff2') format('woff2'); 
  }
.App-HORIZON {
  text-align: center;
  position: relative;
  font-family: "DM-sans";
}
.App-PULSE {
  text-align: center;
  position: relative;
  font-family:  'Satoshi';
 /*background: #ebe8e8;*/
}
/*/SHOPY*/
.App-SHOPY {
  text-align: center;
  position: relative;
  font-family: 'Inter';
  background: #ebe8e8;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
